// Place all the styles related to the comments controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: http://sass-lang.com/

#comments td.date {
  width: 80px;
  text-align: center;
}

#comments td.author {
  width: 150px;
  text-align: center;
}

.comments_holder {
  vertical-align: top;
}