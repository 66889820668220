/* CSS Tabs
  - was created by Joshua Kaufman (http://unraveled.com/) and
  - is licensed under Creative Commons Attribution 2.5 - http://creativecommons.org/licenses/by/2.5/ */
ul#tabnav { /* general settings */
  text-align: left; /* set to left, right or center */
  margin: 1em 0 1em 0; /* set margins as desired */
  border-bottom: 1px solid #98c; /* set border COLOR as desired */
  list-style-type: none;
  padding: 3px 10px 0px 10px; /* THIRD number must change with respect to padding-top (X) below */
}

ul#tabnav li { /* do not change */
  display: inline;
}

ul#tabnav li a { /* settings for all tab links */
  padding: 3px 4px; /* set padding (tab size) as desired; FIRST number must change with respect to padding-top (X) above */
  border: 1px solid #98c; /* set border COLOR as desired; usually matches border color specified in #tabnav */
  background-color: #ccf; /* set unselected tab background color as desired */
  color: #666; /* set unselected tab link color as desired */
  margin-right: 0px; /* set additional spacing between tabs as desired */
  text-decoration: none;
  border-bottom: none;
}

ul#tabnav a:hover { /* settings for hover effect */
  background: #fff; /* set desired hover color */
}

ul#tabnav li.selected_tab { /* settings for selected tab */
  border-bottom: 1px solid #fff; /* set border color to page background color */
  background-color: #fff; /* set background color to match above border color */
}

ul#tabnav li.selected_tab a { /* settings for selected tab link */
  background-color: #f0f0ff; /* set selected tab background color as desired */
  /*color: #000;*/ /* set selected tab link color as desired */
  position: relative;
  top: 1px;
  padding-top: 4px; /* must change with respect to padding (X) above and below */
}