table#part_orders {
  width: 100%;
  font-size: 1em;
}

#part_orders td {
  padding-top: 0;
  padding-bottom: 0;
}

#part_orders td.date {
  width: 9em;
  text-align: center;
}

#part_orders td.price {
  text-align: right;
  width: 5em;
}

#part_orders td.percent:after {
  content: " %";
}

#part_orders td.percent {
  text-align: right;
  width: 4em;
}

#part_orders td.tracking_number {
  width: 6em;
  text-align: center;
}

#part_orders td.user {
  width: 8em;
  text-align: center;
}

#part_orders td.tracking_number:before {
  content: '#';
}

/*.fieldWithErrors {
  display: block;
}

input[type=submit] {
  clear: left;
  float: left;
  margin-right: 1.5em;
}

.cancel {
  display: block;
  padding-top: 0.25em;
}

.row {
  clear: left;
}

.row.right {
  clear: none;
}*/
