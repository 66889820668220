fieldset, div.block{
  border: 1px outset gray;
  padding: 3px;
  margin-bottom: 1em;
  background-color:#EEEEFF;
  /*background-color: #e6e6e6;*/
}

fieldset.invoice {
  padding-top: 20px;
}

form li.row {
  list-style:none;
  margin-bottom: 1em;
  clear: both;
  overflow: hidden;
}

form li.row label {
  display: block;
  width: 16em;
  float: left;
  padding-top: 0.1em;
}

form li.row select {
  font-size: 0.9em;
}

form li.row select:enabled {
  background-color: white;
}

form li.row select:disabled {
  background-color: rgb(235, 235, 228);
}

form li.left {
  list-style:none;
  margin-bottom: 1em;
  float:left;
  width: 50%;
}

form li.right {
  list-style:none;
  margin-bottom: 1em;
  float:right;
  width:50%;
}

form li label.over {
  border-left: 3px solid white;
  border-right: 3px solid white;
  border-top: 3px solid white;
}

form li label.before {
  border-left: 3px solid white;
  border-bottom: 3px solid white;
  border-top: 3px solid white;
}

form li label span.symbol {
  float: right;
  color: #000;
  padding-right: 3px;
}

input, form li.row select, form li.row textarea {
  border: 1px solid #666699;
  /*padding: 0.3em;*/
  width:  unset;
}

form li.row input {
  border: 1px solid #666699;
  display: inline;
  margin: 0;
  width: revert;
}

form li.row input[type=radio] {
  margin-left: .4em;
}

form li.row label.primary_label {
  display: contents;
  color: black;
}

form a.remove_fields {
  padding: .2em;
  margin-left: 1em;
}

form a.add_fields {
  display: block;
  float: left;
  padding: .3em;
  margin-left: 15em;
}

form a.button {
  background-color: #666699;
  color: white;
  text-decoration: none;
  border: 1px outset gray;
}

form a.padded_button {
  display: block;
  background-color: #666699;
  border: 1px outset gray;
  padding: 6px;
  color:white;
}

input[type=submit], button, .button-to input[type=image], a.button-to{
  display: block;
  background-color: #666699;
  border: 1px outset gray;
  padding: 6px;
  color:white;
}

.form.inline input[type=submit] {
  display: inline;
  padding-top: 3px;
  padding-bottom: 3px;
}

.form.inline label,
.form.inline input[type=submit] {
  margin-left: 5px;
}

#customer_submit {
  font-size: 13px;
}
input[type=submit].small_text_button {
  display: block;
  position: relative;
  background-color: #666699;
  border: 1px outset gray;
  padding: 6px;
  color: white;
  font-size: 0.8em;
}
input, textarea,select{
  border: 0;
}

textarea {
  width: 283px;
}

input.with_border {
  border: 1px solid grey;
  width:  revert;
}

input.state {
  width: 3.5em;
}
input.name {
  width: 10em;
}

input.zip {
  width: 4.5em;
}

input.city {
  width:13.34em;
}

input.phone_type {
  width:4em;
}

li.row input[type=text].phone_number {
  width: 9em;
}

input.phone_extension {
  width:5em;
}

input.phone_name {
  width: 7.5em;
}

input.email_name {
  width: 9em;
}

label {
  size:0.8em;
  color:gray;
}

input[type=submit]{
  margin:auto;
  clear: both;
}

.long-text-input{
  height:6em;
  width:40em;
}

.settings-form .description {
  padding-left: 9.5em;
  font-size: 85%;
}

#create_and_cancel {
  position: relative;
  top: -8px;
  left: 40%;
}

#part_orders_cancel {
  display: table-cell;
  padding: 7px;
  border-top: 1px outset white;
  border-left: 1px outset white;
  border-right: 1px outset grey;
  border-bottom: 1px outset grey;
}

#part_orders_fieldset {
 padding-bottom: 10px;
}

#service_request_submit {
  margin-top: 30px;
}

ul.invoice_form {
  position: relative;
  left: -12px;
}

