form#search_customer {
  clear: both;
}

h1.search_header {
  float: left;
}

a#create_new_customer {
  font-size: 1.2em;
  float: right;
  padding-top: 1em;
}

ul#customers{
  list-style-type:none;
}

ul.customers-list {
  list-style-type:none;
  font-size: 1.0em;
  padding-left: 50px;
}

ul.customers-list li {
  display: block;
  padding: 6px;
  font-size: 1.5em;
}

#customers li{
  /*display: block;*/
  padding: 8px;
}

#customer_first_name {
  width: 138px;
}

#customer_last_name {
  width: 130px;
}

#customer_address {
  width: 283px;
}

#customer_state.state {
  width: 34px;
}

#customer_zip.zip {
  width: 53px;
}

#customer_city {
  width: 138px;
}

#customer_phone_prefix.phone_prefix {
  width: 4em;
}

#customer_phone.phone {

}

#customer_phone_ext.phone_ext {

}

#customer_phone2_prefix.phone_prefix {
  width: 4em;
}

#customer_phone2.phone {
  width: 10em;
}

#customer_phone2_ext.phone_ext {
  width: 5em;
}

#customer_phone_numbers {
  width: auto;
  border-style: none;
  border-collapse: collapse;
}

#customer_phone_numbers td {
  margin: 0px;
  padding: 0px;
  border-style: none;
}

#customer_phone_numbers td.customer_phone_type {
  width: 3.5em;
}
#customer_phone_numbers td.customer_phone_number {
  width: 9em;
}
#customer_phone_numbers td.customer_phone_extension {
  width: 5em;
}
#customer_phone_numbers td.customer_phone_name {
  width: 15em;
}

#merge_wrapper {
  display: flex;
}

#merge_wrapper td {
  background-color: #EEEEFF;
}

#merge_customer1 {
}

#merge_customer_separator {
  flex-grow: 1;
  font-size: xx-large;
  text-align: center;
  margin-top: 2em;
}

#merge_customer2 {
}

.address_street {
  margin-top: 3px;
}

input#copies_count {
  line-height: normal;
  font-size: 13px;
  margin:  0;
  height:  19px;
}

/*
  Customer Options Menu
 */

div.customer-menu-dropdown {
  float: right;
}

div.customer-menu-dropdown .dropdown.menu > li.is-dropdown-submenu-parent > a {
  padding-left: 1.7rem;
  padding-right: 0.7rem;
  color:  black;

  border: #e3e3e3;
  border-style: solid;
  border-width: thin;
  border-radius: 6px;
}

div.customer-menu-dropdown .dropdown.menu > li.is-dropdown-submenu-parent > a:hover {
  background-color: #e3e3e3;
  border: #adadad;
  border-style: solid;
  border-width: thin;
  border-radius: 6px;
}

div.customer-menu-dropdown .dropdown.menu > li.is-dropdown-submenu-parent > a:focus {
  background-color: #cfcfcf;
  border: #9e9e9e;
  border-style: solid;
  border-width: thin;
  border-radius: 6px;
}

div.customer-menu-dropdown .dropdown.menu > li.is-dropdown-submenu-parent > a::after {
  border-color: black transparent transparent;
  right: auto;
  left: 10px;
  /*border: inset 5px;*/
}

div.customer-menu-dropdown .dropdown .is-dropdown-submenu a {
  color: black;
}

div.customer-menu-dropdown .dropdown.menu > li.opens-left > .is-dropdown-submenu {
  margin-top: 3px;
  border-radius: 6px;
}

div.customer-menu-dropdown li.is-submenu-item.is-dropdown-submenu-item:hover {
  background-color: #f7f7f7;
}

div.customer-menu-dropdown ul.menu.submenu.is-dropdown-submenu.first-sub.vertical.js-dropdown-active.menu-wide {
  min-width: 275px;
}

div.customer-menu-dropdown i.dropdown-menu-icon {
  margin-right: 7px;
}

.dropdown.menu .divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #cacaca;
}

.dropdown.menu li.is-submenu-item button.button.clear {
  margin: inherit;
  color: black;
}
/*
ul.customers-list a {
  text-decoration: underline;
}

ul.customers-list a:hover {
  background-color: revert;
  text-decoration: underline;
}
*/