span.collapse_arrow {
  text-align: center;
}

span.collapse_arrow::after {
  content: url("~images/expand_arrow.svg");
  display: inline-block;
}

span.collapse_arrow.expand::after {
  content: url("~images/collapse_arrow.svg");
}