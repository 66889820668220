.input_field {
  display: block;
}

tr.header {
  background-color: white;
  cursor: pointer;
}

tr.header td.sign_holder {
  text-align: center;
}

.header .sign:after {
  content: "+";
  display: inline-block;
}

.header.expand .sign:after {
  content: "-";
}

.header + tr {
  display: none;
}

.sign {
  font-size: xx-large;
}

.cell_spacer {
  background-color: #e7e7e7;
  border: 0px;
}

table.bills > thead > tr > th:nth-child(1) {
  width: 35px;
}

table#bill_payments_list {
  margin-left: 27px;
  width: calc(100% - 27px);
  width: -moz-calc(100% - 27px);
  width: -webkit-calc(100% - 27px);
}

table#bill_payments_list td {
  text-align: center;
}

th.service_requests.id_number {
  width: 85px;
}

th.service_requests.date {
  width: 65px;
}

th.service_requests.money {
  width: 100px;
}

th.service_requests.action_button {
  width: 65px;
}

table.bills .button_to input[type=submit], table.bills a.button-to, table.bills .link a, table.bills .edit_link a {
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  text-indent: -100em;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
}

a:button-to {
  cursor: default;
}

th.service_requests.customer_contact {
  min-width: 140px;
}

input.datalist_filter::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 1; /* Firefox */
}

input.datalist_filter:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: black;
}

input.datalist_filter::-ms-input-placeholder { /* Microsoft Edge */
  color: black;
}