.custom-combobox {
  position: relative;
  display: inline-block;
}
.custom-combobox-toggle {
  position: absolute;
  top: 0;
  bottom: 0;
  margin-left: -1px;
  padding: 0;
}
.custom-combobox-input {
  margin: 0;
  padding: 5px 10px;
}

.ui-autocomplete {
  position: absolute;
  cursor: default;
  z-index:30 !important;
}