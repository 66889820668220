table#call_log{
  width:100%;
}
#call_log td.user{
  width:10em;
  text-align:center;
}
#call_log td.date{
  width:9em;
  text-align:center;
}

