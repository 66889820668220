#notice {
  float: left;
  z-index: 10;
  position: relative;
  left: 50px;
  top: 30px;
  text-align: center;
  width: 70px;
  padding: 4px;
  border-style: solid;
  border-width: 3px;
  background-color: white;
  color: red; }

.field_with_errors input {
  background-color: rgb(252, 223, 255) !important;
}

.field_with_errors textarea {
  background-color: rgb(252, 223, 255) !important;
}


.field_with_errors {
  display: inline;
}

#error_explanation {
  width: 450px;
  border: 2px solid red;
  padding: 7px;
  padding-bottom: 0;
  margin-bottom: 20px;
  background-color: #f0f0f0;
  h2 {
    text-align: left;
    font-weight: bold;
    padding: 5px 5px 5px 15px;
    font-size: 12px;
    margin: -7px;
    margin-bottom: 0px;
    background-color: #c00;
    color: #fff; }
  ul li {
    font-size: 12px;
    list-style: square; } }
