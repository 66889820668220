div.options-menu-dropdown .dropdown.menu > li.is-dropdown-submenu-parent > a {
  padding-left: 1.7rem;
  padding-right: 0.7rem;
  color:  black;

  border: #e3e3e3;
  border-style: solid;
  border-width: thin;
  border-radius: 6px;
}

div.options-menu-dropdown .dropdown.menu > li.is-dropdown-submenu-parent > a.no-arrow {
  padding-left: 0.7rem;
  padding-right: 0.7rem;
}

div.options-menu-dropdown .dropdown.menu > li.is-dropdown-submenu-parent > a:hover {
  background-color: #e3e3e3;
  border: #adadad;
  border-style: solid;
  border-width: thin;
  border-radius: 6px;
}

div.options-menu-dropdown .dropdown.menu > li.is-dropdown-submenu-parent > a:focus {
  background-color: #cfcfcf;
  border: #9e9e9e;
  border-style: solid;
  border-width: thin;
  border-radius: 6px;
}

div.options-menu-dropdown .dropdown.menu > li.is-dropdown-submenu-parent > a::after {
  border-color: black transparent transparent;
  right: auto;
  left: 10px;
  /*border: inset 5px;*/
}

div.options-menu-dropdown .dropdown.menu > li.is-dropdown-submenu-parent > a.no-arrow::after {
  display: none;
}

div.options-menu-dropdown .dropdown .is-dropdown-submenu a {
  color: black;
}

div.options-menu-dropdown .dropdown.menu > li.opens-left > .is-dropdown-submenu {
  margin-top: 3px;
  border-radius: 6px;
  width: 325px;
}

div.options-menu-dropdown li.is-submenu-item.is-dropdown-submenu-item:hover {
  background-color: #f7f7f7;
}

div.options-menu-dropdown ul.menu.submenu.is-dropdown-submenu.first-sub.vertical.js-dropdown-active {
  // min-width: 250px;
}

div.options-menu-dropdown i.fi-widget.dropdown-menu-icon {
  margin-right: 7px;
}
li.is-submenu-item div.flex-container > div.flex-child-shrink {
  min-width: 12px;
  margin-right: 7px;
}

li.is-submenu-item div.flex-container > div.flex-child-grow a {
  // padding-bottom: 0.6rem;
}

i.fi-dollar.dropdown-menu-icon {
  font-size: 20px;
  padding-left: 1px;
}

.dropdown .is-dropdown-submenu .is-dropdown-submenu-item > div {
  padding: 0.7rem 0.8rem;
}
