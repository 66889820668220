div.modal-header {
  padding: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

div.modal-body {
  padding: 1rem;
  padding-top: 0.5rem;
}
