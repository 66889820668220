/***** a11y-dialog https://a11y-dialog.netlify.app/usage/styling *****/

/**
 * 1. Make the dialog container, and its child overlay spread across
 *    the entire window.
 */
.dialog-container,
.dialog-overlay {
  position: fixed; /* 1 */
  top: 0; /* 1 */
  right: 0; /* 1 */
  bottom: 0; /* 1 */
  left: 0; /* 1 */
  width: 100%;
  height: 100vh;
  overflow: auto;
}

/**
 * 1. Make sure the dialog container and all its descendants sits on
 *    top of the rest of the page.
 * 2. Make the dialog container a flex container to easily center the
 *    dialog.
 */
.dialog-container {
  z-index: 99; /* 1 */
  display: flex; /* 2 */
  align-items: center;
  justify-content: center;
}

/**
 * 1. Make sure the dialog container and all its descendants are not
 *    visible and not focusable when it is hidden.
 */
.dialog-container[aria-hidden='true'] {
  display: none; /* 1 */
}

/**
 * 1. Make the overlay look like an overlay.
 */
.dialog-overlay {
  background-color: rgba(43, 46, 56, 0.9); /* 1 */
}

/**
 * 1. Vertically and horizontally center the dialog in the page.
 * 2. Make sure the dialog sits on top of the overlay.
 * 3. Make sure the dialog has an opaque background.
 */
.dialog-content {
  margin: auto; /* 1 */
  z-index: 22;
  position: absolute;
  background-color: white;
  border-radius: 6px;
  max-width: 700px;
  width: 100%;
  top: 15%;
  left: 50%;
  transform: translateX(-50%);
}

.inner-container {
  padding: 1em;
}

/*
  Close Modal Button
*/
.btn-close {
  background: none;
  border: none;
  cursor: pointer;
  font-family: arial;
  font-size: 2em;
  font-weight: 800;
  line-height: 1;
  padding: .25em;
  position: absolute;
  right: .5em;
  top: .5em;
  z-index: 99;
  color: white;
}

.btn-close:hover {
  border: 2px solid #fff;
  border-radius: 8px;
}
