table#order_parts td {
  text-align: center;
}

li.row.left {
  clear: left;
}

li.row.right {
  clear: none;
}
