/* Dropdown Button */
.workspace_settings_dropbtn {
    background-color: #4CAF50;
    color: white;
    font-size: 16px;
    border: none;
    cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.workspace_settings_dropdown {
    position: relative;
    display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.workspace_settings_dropdown_content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 100%;
    right: 0;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    overflow: hidden;
}

/* Divs inside the dropdown */
.workspace_settings_dropdown_content div {
    color: black;
    padding: 6px 10px;
    text-decoration: none;
    display: block;
}

/* Divs inside the dropdown */
.workspace_settings_dropdown_content_item label {
    width: 100%;
    text-align: left;
    white-space: nowrap;
}

/* Change color of dropdown divs on hover */
.workspace_settings_dropdown_content div:hover {background-color: #f1f1f1}

/* Show the dropdown menu on hover */
.workspace_settings_dropdown:hover .workspace_settings_dropdown_content {
    display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.workspace_settings_dropdown:hover .workspace_settings_dropbtn {
    background-color: #3e8e41;
}
