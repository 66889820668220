tbody {
  background-color: #FFFFFF;
}

tr.odd {
  background: #F9F9F9 none repeat scroll 0%;
}

td, th {
  padding: 0.5em;
}

td.edit_link {
  width: 4em;
  text-align: center;
}

td.delete_link {
  width: 5em;
  text-align: center;
}

table {
  background-color: #E7E7E7;
  border-spacing: 1px !important;
  color: black;
  width: 100%;
  border-collapse: separate !important;
}

table td {
  /*border: 1px solid #FFFFFF;*/
  border-spacing: 1px !important;
}

tfoot, thead {
  color: black;
  width: 100%;
}

tfoot td, thead {
  background: #F0F0F0 none repeat scroll 0%;
  border-bottom: 1px solid #999999;
  border-left: 1px solid #FFFFFF;
  border-right: 0;
  border-top: 0;
  /*color: #666666;*/
  color: black;
  text-align: center;
  padding: 4px;
  font-weight: bold;
}

thead td.title {
  text-align: center;
  font-size: large;
  background-color: white;
}

tfoot td {
  border-top: 1px solid #999999;
  border-bottom: 0;
}

tfoot td.label {
  text-align: right;
  display: table-cell;
  background: #f0f0f0;
  color: black;
}

th.hidden {
  border: 0;
}

td.hidden {
  visibility: hidden;
  border: none;
}

table.earnings {
  border-spacing: 1px;
  border-left: 1px solid lightgrey;
}

td {
  background: transparent;
}

td.earnings {
  height: 25px;
  border: 1px solid #FFFFFF;
}

tbody.earnings {
  border: 1px solid lightgrey;
}

tr.earnings {
}

.no_border {
  border-style: none;
}

.presentation_only {
  background-color: #eeeeff;
  vertical-align: top;
}

table#active_orders input {
  max-width: 250px;
  margin: 0;
  font-size: 0.9rem;
}

table#active_orders div.no-wrap input {
  display: revert;
}
