/* unopned_circle */
.unopened_wrapper{
  position: absolute;
  margin-top: 20px;
  margin-left: 56px;
}
.unopened_wrapper .unopned_circle {
  display: block;
  width: 10px;
  height: 10px;
  position: absolute;
  border-radius: 50%;
  background-color: #27a5eb;
  z-index: 2;
}
.unopened_wrapper:hover > .unopned_description_wrapper{
  display: block;
}
.unopened_wrapper .unopned_description_wrapper {
  display: none;
  position: absolute;
  margin-top: 26px;
  margin-left: -24px;
}
.unopened_wrapper .unopned_description_wrapper .unopned_description {
  position: absolute;
  color: #fff;
  font-size: 12px;
  text-align: center;

  border-radius: 4px;
  background: rgba(0, 0, 0, 0.8);
  padding: 4px 12px;
  z-index: 999;
}
.unopened_wrapper .unopned_description_wrapper .unopned_description:before {
   border: solid transparent;
   border-top-width: 0;
   content: "";
   display: block;
   position: absolute;
   width: 0;
   left: 50%;
   top: -5px;
   margin-left: -5px;
   height: 0;
   border-width: 0 5px 5px 5px;
   border-color: transparent transparent rgba(0, 0, 0, 0.8) transparent;
   z-index: 0;
}

/* list */
.notification_list {
  padding: 15px 10px;
  position: relative;
  border-bottom: 1px solid #e5e5e5;
}
.notification_list.unopened {
  background-color: #eeeff4;
}
.notification_list:hover {
  background-color: #f8f9fb;
}
.notification_list:after{
  content: "";
  clear: both;
  display: block;
}
.notification_list .notification_list_cover{
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

}
.notification_list .list_image {
  float: left;
  width: 40px;
  height: 40px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #979797;
}
.notification_list .list_text_wrapper {
  float: left;
  width: calc(100% - 60px);
  margin-left: 20px;
}
.notification_list .list_text_wrapper .list_text {
  color: #4f4f4f;
  font-size: 14px;
  line-height: 1.4;
  margin-top: 0;
  height: auto;
  font-weight: normal;
}
.notification_list .list_text_wrapper .list_text strong{
  font-weight: bold;
}

.notification_list .list_text_wrapper .list_text .category_priority{
  color: red;
  font-size: 14px;
  font-weight: bold;
}

div.notification_destroy_box {
  width: 20px;
  height: 20px;
  background-color: #000000;
  opacity: 0.1;
  filter:alpha(opacity=0.1);
  border-radius: 5px;
  position: absolute;
  top: 2px;
  right: 2px;
  z-index: 2;

  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

div.notification_destroy_box:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 1px;
  right: 0;
  content: "\274c";
  font-size: 21px;
  color: #FFF;
  line-height: 20px;
  text-align: center;
}

div.notification_destroy_box:hover {
  opacity:0.5;
  filter:alpha(opacity=100);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
