/*
  Place all the styles related to the matching controller here.
  They will automatically be included in application.css.
*/

td.input {
  background-color: black;
}

table#parts_table td.wide {
  width: 100%;
}

table#parts_table td:not(.wide) {
  min-width: 65px;
  text-align: center;
}

#new_licenses_input {
  width: 70%;
  min-height: 100px;
  resize: vertical;
}