#save_and_edit {
  position: relative;
  left: 45%;
  margin-top: 10px;
}

/*fieldset {
  padding: 10px 0 0 0;
}*/
#search_knowledges_form {
  position: relative;
  left: 70px;
}

#search_submit_tag {
  position: relative;
  margin-bottom: 3px;
  margin-left: 50%;
  display: inline;
}

/*tr:hover {
  background-color: white;
}

tr.hover:hover {
  background-color: #EEEEFF;
}*/

#knowledge_show {
  padding-bottom: 3px;
}
