@use "legacy/activity_notifications";
@use "legacy/a11y-dialog";
@use "legacy/base";
@use "legacy/bills";
@use "legacy/calls";
@use "legacy/comments";
@use "legacy/customers";
@use "legacy/fonts";
@use "legacy/forms";
@use "legacy/knowledges";
@use "legacy/order_parts";
@use "legacy/parts";
@use "legacy/part_orders";
@use "legacy/payments";
@use "legacy/scaffolds";
@use "legacy/shared/collapsible";
@use "legacy/shared/combobox";
@use "legacy/shared/dropdown_menu";
@use "legacy/shared/menu";
@use "legacy/shared/modal";
@use "legacy/shared/navigation_dropdown";
@use "legacy/table";
@use "legacy/tabs";
@use "legacy/toastr.min";
@use "legacy/weekly";

@use "~jquery-ui-dist/jquery-ui";

@import 'packages/foundation/foundation_and_overrides';
@import 'packages/foundation/foundation-icons/foundation-icons';

@import "tailwind.css";
@import 'primeicons/primeicons.css';
@import "~flatpickr/dist/flatpickr.css";

ul#navigation {
  line-height: 1.231;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.padding-0 {
  padding: 0;
}

.revert-font-size {
  font-size: revert;
}

select#tender_select {
  width: 90px;
}

i.dropdown-menu-icon {
  font-size: 20px;
}

*, *:before, *:after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  /*background-color: #9999CC;*/
  background-color: #e6e6e6;
}

.tax {
  width: 12px;
}

body {
  margin: 0;
  padding: 10px;
  height: 100%;
}

div#main_window {
  margin: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  border: 1px solid black;
  padding: 10px;
  background: white;
  position: relative;
}

.flash {
  padding: 0.125em;
  text-align: center;
  font-size: 150%;
  color: #EFC;
  background-color: #4C4;
}

fieldset {
  margin: 0px;
  padding: 0px;
}

fieldset.service_request {
  padding: 20px 0 5px 0;
}

fieldset.service_request li.row textarea {
  width: 400px;
  height: 130px;
}

fieldset#login {
  width: 200px;
  padding: 20px;
  margin: auto;
}

input.table_string {
  width: 100%;
}

.fieldWithErrors {
  background-color: pink;
  background-image: none;
  background-repeat: repeat;
  background-attachment: scroll;
  background-position: 0% 0%;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: auto auto;
}

.fieldWithErrors input {
  background: none repeat scroll 0% 0% rgb(252, 223, 255);
}

.errorExplanation {
  color: red;
}

ul#service_request_navigation {
  list-style-type: none;
  float: right;
  margin-bottom: 10px;
}

ul#service_request_navigation li {
  display: inline;
  margin-left: 1em;
}

.flex-container {
  display: -webkit-flex;
  display: flex;
  align-items: center;
  width: 80%;
  margin-right: auto;
  margin-left: auto;
  padding: 0;
  flex-wrap: wrap;
  justify-content: flex-end;
}

ul#navigation {

  /*  position: relative;
    direction: rtl;
    float:right;
    right: 7%;*/
  margin-bottom: 0px;
  margin-top: 0px;
}

ul#navigation li {
  display: inline;
  margin: 2px;
  padding: 0.5em;
  background-color: #666699;
  border: 1px outset white;
  color: white;
  font-size: 1em;
  white-space: nowrap;
}

ul#navigation li:hover {
  border: 1px solid white;
}

#navigation li a:visited, #navigation li a:link, #navigation li a {
  text-decoration: none;
  color: white;
}

#navigation li a:hover {
  text-decoration: underline;
}

#notification_bell {
  position: absolute;
  left: 90%;
  top: 15px;
}

#shopping_cart {
  position: absolute;
  left: 92%;
  top: 15px;
}

#shopping_cart_count {
  text-align: center;
  font-weight: 1000;
  color: darkorange;
}

#specification_list td {
  padding: 0;
  margin: 0;
}

#specification_list {
  width: 97%;
  margin: 0 0 16px 0;
  border: none;
}

#specification_list  input {
  width: 100%;
}

#specification_list input.right {
}

#specification_list:first_child input {
}

.line_break_plus {
  position: relative;
  top: 6px;
}

.specs_navigation {
  margin-bottom: 16px;
}

div.line_items_info {
  clear: both;
}

#read_only_message {
  text-align: center;
  clear: both;
}

#found_us {
  float: right;
  overflow: hidden;
  display: block;
  text-align: left;
  width: 44%;
}

span.request_date {
  font-size: 85%;
  font-style: italic;
  color: #777;
}

div.fieldWithErrors textarea {
  background: #FCDFFF;
}

div.fieldWithErrors input {
  background: #FCDFFF;
}

form.edit_service_request label.error {
  position: absolute;
  left: 0px;
  top: -500px;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

form.edit_service_request textarea.error {
  background: #FCDFFF;
}

form.edit_service_request input.error {
  background: #FCDFFF;
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

input.q {
  border-style: solid;
  border-color: black;
  border-width: 1px;
}

form.item-action {
  display: inline-block;
  margin-bottom: -0.25em;
}

.item-action input[type=submit] {
  padding: 0;
  margin: 0 0.25em;
}

tr.grandtotal td {
  background: #CFCFCF;
}

/* Currently used for the Payments button*/
#title_window h1 {
  display: inline-block;
}

.button_holder {
  float: right;
}

/**/
#search_service_requests {
  background-color: #F0F0F0;
  border-style: solid;
  border-width: 1px;
  border-color: black;
  padding: 4px;
}

input.search {
  border-style: solid;
  border-color: black;
  border-width: 1px;
}

h1 {
  font-size: 138.5%;
}

h2 {
  font-size: 123.1%;
}

h3 {
  font-size: 108%;
}

h1, h2, h3 {
  margin: 1em 0;
}

h1, h2, h3, h4, h5, h6, strong {
  font-weight: bold;
}

abbr, acronym {
  border-bottom: 1px dotted #000;
  cursor: help;
}

em {
  font-style: italic;
}

blockquote, ol, dl {
  margin: 1em;
}

ol, dl {
  margin-left: 2em;
}

/*ol, li {
  list-style: decimal outside;
}

ul, li {
  list-style: disc outside;
}*/

dl, dd {
  margin-left: 1em;
}

th {
  font-weight: bold;
  text-align: center;
  background-color: #EEF;
}

tr.not_even {
  background-color: #F9F9F9;
}

tr.even {
  background-color: #FFFFFF;
  /*height: 40px;*/
}

tr:not(.header_row, .employee_header, .row_header):hover {
  background-color: #EEEEFF;
}

caption {
  margin-bottom: .5em;
  text-align: center;
}

p, table, pre {
  margin-bottom: 1em;
}

input[type=text].normal {
  width: 12.25em;
}

input[type=text].no_width {
  width: unset;
}

input[type=text].setting {
  width: 283px;
}

input[type=text].small {
  width: 6em;
}

th.earnings {
  border: 1px solid black;
  border-spacing: 0px;
  background-color: white;
}

th.service_requests {
  white-space: pre;
  background-color: #F0F0F0;
  border-top: 0;
  border-bottom: 1px solid #999;
  border-left: 1px solid #FFF;
  border-right: none;
}

th#service-requests-column-created {
  width: 235px;
}

table#active_orders tbody > tr.odd {
  background-color: #F9F9F9;
}

tr.odd {
  background-color: #F9F9F9;
  /*height: 40px;*/
}

#knowledge_search_text_field {
  width: 400px;
}

input.edit_button_next_to_save {
  position: relative;
  top: -29px;
  left: 55px;
}

/* ##### Added from line_items.css */
/*table#line_items {
  width: 100%;
  font-size: 0.7em;
}*/

td.edit_link, td.delete_link, td.submit_button {
  text-align: center;
  width: 4em;
}

td.price {
  text-align: right;
  width: 7em;
}

td.percent:after {
  content: " %";
}

td.percent {
  text-align: right;
  width: 4em;
}

td.price:before {
  content: "$";
}

td.tracking_no, td.hours {
  width: 6em;
  text-align: center;
}

td.user {
  width: 8em;
  text-align: center;
}

td.tracking_no:before {
  content: '#';
}

td.hours:after {
  content: ' h';
}

td.tracking_no input, td.hours input {
  width: 80%;
}

td.price input {
  width: 75%;
}

td.description input {
  width: 95%
}

td.invoice_total {
  white-space: nowrap;
}

tr.total {
  font-weight: bolder;
  border: 2px solid black;
}

.button {
  vertical-align: center;
  margin: auto;
}

.button#make_invoice {
  font-weight: bolder;
  margin: auto;
  margin-bottom: 10px;
  border-width: 3px;
  display: block;
  clear: both;
}

#fields_add_part {
  margin-top: 10px;
}

#fields_add_part input[type=submit] {
  margin-left: auto;
  margin-right: auto;
  /*float: right;*/
}

#fields_add_labor {
  margin-top: 10px;
}

#fields_add_work input[type=submit] {
  margin-left: auto;
  margin-right: auto;
  float: right;
}

.button.minor {
  display: inline-block;
  padding: 0.0625rem 0.3125rem;
  font-size: 0.6875rem;
}

.dialog_content {
  display: none;
}

a.standard_button {
  display: inline-block;
  margin: 2px 2px 2px 2px;
  padding: 0.5em;
  background-color: #666699;
  border: 1px outset white;
  color: white;
  font-size: 1em;
  white-space: nowrap;
  text-decoration: none;
  cursor: default;
}

a.disabled {
  cursor: not-allowed;
  background-color: #9e9e9e;
  /*pointer-events: none;*/
}

.add_to_cart_button {
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  text-indent: -100em;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("~images/cart_button.svg");
  padding-bottom: 30px;
}

.add_to_cart_button.unbilled_service_request {
  position: absolute;
  top: 0px;
  right: 20px;
}

.bills .card .button-to {
  background-image: url("~images/card_terminal.svg");
}

.bills .paid .button-to {
  background-image: url("~images/glyphicons_227_usd.invs_28x28.png");
}

.bills .unpaid .button-to {
  background-image: url("~images/undo-arrow.svg");
}

.bills .reopen .button-to {
  background-image: url("~images/glyphicons_151_edit.invs_28x28.png");
}

.bills .email .button-to {
  background-image: url("~images/glyphicons_129_message_new.invs_28x28.png");
  display: inline-block;
  margin: 2px 2px 2px 2px;
  padding: 0.5em;
  background-color: #666699;
  border: 1px outset white;
  color: white;
  font-size: 1em;
  white-space: nowrap;
  text-decoration: none;
  cursor: default;
  width: 48px;
  height: 48px;
  text-indent: -100em;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
}

.approve_quote .button-to {
  background-image: url("~images/checkmark.svg");
}

.bills .edit_link a {
  background-image: url("~images/glyphicons_151_edit.invs_28x28.png");
  background-color: #666699;
}

.bills .link a {
  background-image: url("~images/glyphicons_025_binoculars.h220s19l49_28x28.png");
}

.bills.link a:hover {
  opacity: 0.8;
}

input::-webkit-calendar-picker-indicator {
  display: none;/* remove default arrow */
}
/*.myarrow:after {
    content: url("~images/dropdown_arrow.svg");
    margin-left: -20px;
    padding: .1em;
    pointer-events:none;
}*/

.field_with_errors select {
  background-color: rgb(252, 223, 255) !important;
}

.radio_option label {
  text-align: center;
}

.centered {
  text-align: center;
}

@media all and (max-width: 430px) and (min-width: 360px) {
  /* put your mobile css in here */
}

.float_left {
  float: left;
}

.toast {
    opacity: .9 !important;
}

footer {
  background-color: #666699;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  width: 100%;
  border-top: 1px solid white;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

footer p {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

ul.pagination {
  margin: 0;
  margin-right: 15px;
  float: left;
}

ul.pagination a {
  padding: 0;
  margin: 0;
  float: left;
}

div#customers ul.pagination > li {
  padding: 0;
  margin: 0;
  display: inline-flex;
}

.pagination .current {
  height: fit-content;
}

#problem_category_list {
  width: 48%;
  float: left;
}

#accessory_category_list {
  width: 48%;
  float: right;
}

.rounded-corners {
  border-radius: 6px;
}

.button-group.no-gaps.rounded-corners > .button:first-child:not(:last-child):not(.dropdown-toggle) {
  border-radius: 6px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.button-group.no-gaps.rounded-corners > .button:last-child:not(:first-child),
.button-group.no-gaps.rounded-corners > .dropdown-toggle:not(:first-child) {
  border-radius: 6px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

div.divider {
  height: 0px;
  width: auto;
  border-bottom: 1px solid #cacaca;
}

.no-padding {
  padding: 0;
}

input.date-picker {
  background-color: inherit;
}

.button.badge {
  margin: auto;
  margin-right: 10px;
  cursor: revert;
}

.button.badge.primary:hover {
  background-color: #1779ba;
  color: #fefefe;
}

.no-hover {
  pointer-events: none;
}

.revert-cursor {
  cursor: revert;
}

.no-wrap {
  white-space: nowrap;
}

.best-in-place-text-area {
  width: 100%;
  text-align: left;
}

.best-in-place-text-area > form > textarea {
  font-size: 13px;
}

#velocity_button_placeholder {
  background-color: #666699;
  border: 1px outset gray;
  padding: 6px;
}

#receipt_dialog {
  overflow: hidden;
}

.dialog_content.ui-dialog-content.ui-widget-content {
  overflow: hidden;
}

.ui-dialog .ui-dialog-buttonpane {
    text-align: center;
}

.ui-dialog .ui-dialog-buttonpane .ui-dialog-buttonset {
    float: none;
}

fieldset.payment .button:hover {
  background-color: #666699;
}
