// Place all the styles related to the Payments controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: http://sass-lang.com/
.button_to .payment_button {
  background-image: url('~images/card_terminal.svg');
}
.payment_button {
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  text-indent: -100em;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url('~images/card_terminal.svg');
}

.payment_button.unbilled_service_request {
  position: absolute;
  top: 0px;
  right: 68px;
}

#payment_undo_dialog {
  padding: .5em .5em;
}

#payments_edit_form {
  position: relative;
}

div#related_payments {
  position: absolute;
  top: 1em;
  right: 1em;
  box-shadow: 0 0 8px #666;
}

table#related_payments {
  margin-bottom: 0;
}

input[type="number"]#card_number {
   width: 14.3em;
}

table.order_payments > thead {
  line-height: 5px;
}

table#payments > thead th {
  white-space: pre;
  background-color: #F0F0F0;
  border-top: 0;
  border-bottom: 1px solid #999;
  border-left: 1px solid #FFF;
  border-right: none;
}

#manual_payment, #manual_complete_transaction {
  display: table;
  margin: auto;
}

.payment_sidebar {
  float: right;
  border: 2px solid #9999CC;
  height: 200px;
}

#combobox {
  text-align: center;
}

.label.total > div {
  float: right;
}

.checkout_total {
  max-width: 80px;
  text-align: center;
}

.checkout_column_width {
  width: 90px;
}

#payment_remove_column {
  max-width: 24px;
}

input.payment_amount_input {
  width: 80px;
}
