/* -- WeeklyBuilder -- */

/* layout */
/**, *:before, *:after {
  box-sizing: inherit;
}*/

/*html {
  box-sizing: border-box;
}*/

.week {
  background: #FFF;
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  overflow: hidden;
}

div.header_table {
  float: left;
  width: 15%;
}

table.header_table {
  color: #000;
}

.scrollable_body_table {
  width: 85%;
  overflow: auto;
  table-layout: fixed;
  float: right;
}

.body_table {
  table-layout: fixed;
  color: #000;
  background-color: #c5c5c5;
}

.body_table .day_row, table.body_table > tbody > tr.employee_day_row {
  background-color: #f5ebba;
}

.body_table .day_row:hover, .employee_day_row:hover {
  background-color: #EEEEFF;
}

/* boxes */
.header_row {
  width: 1125px;
  height: 55px;
  background: #A8CFDA;
  margin-bottom: 1px;
}

.header_box {
  height: 56px;
  width: 75px;
  border-right: 1px solid #cfe6ec;
  padding: 10px 1px 7px 18px;
}

.day_row {
  width: 1125px;
  height: 75px;
  position: relative;
}

.hour {
  height: 75px;
  width: 55px;
  border: none;
  padding: 0 0 0 0;
}

.hour .divider {
  display: inline-block;
  position: absolute;
  width: 0;
  height: 100%;
  left: 50%;
  border-left: 1px dotted #a3a3a3;
}

.employee_day_row .hour {
  height: 55px
}

.container {
  display: block;
  position: relative;
  height: 100%;
  width: 100%;
}

.day {
  height: 75px;
  border: none;
  border-right: 1px solid #8F8F8F;
  border-top: 1px solid #ccc;
  padding: 17px 0px 0px 18px;
}

.employee_name {
  height: 55px;
  text-align: right;
  border-right: 1px solid #8F8F8F;
  border-top: 1px solid #ccc;
  padding: 17px 18px 0px 0px;
  white-space: nowrap;
}

.placeholder {
  height: 55px;
  border-top: 1px solid #fff;
  border-right: 1px solid #ccc;
  padding: 15px 0px 0px 18px;
}

.week_event {
/*  background: #CD8AA4;*/
  float: left;
  position: relative;
  z-index: 1;
  color: #000;
  border-left: 4px solid #5A484F;
  opacity: 0.90;
  filter: alpha(opacity=90);
  margin-top: 1px;
}

.week_event a {
  color: #000;
  display: block;
}

.week_event:hover {
  background: #E097B3;
}

.day_row .week_event {
  height: 73px;
}

.employee_day_row .week_event {
  height: 53px;
}

.click_hour {
  height: 57px;
  width: 55px;
  border-right: 1px solid #cfe6ec;
  padding: 17px 1px 0px 18px;
  float: left;
}

.click_hour a {
  color: #000;
}

.click_hour:hover {
  background: #C7F980;
}

/* 24-hour calendar view */
.full_day_row {
  width: 1800px;
  height: 75px;
  position: relative;
}

.full_header_row {
  width: 1800px;
  height: 54px;
  background: #A8CFDA;
}
